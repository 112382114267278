import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18next from './utils/i18n.js';

import router from './routes/index.js';

import './styles/index.css';
export default function App() {
  return (
      <I18nextProvider i18n={i18next}>
        <RouterProvider router={router} />
      </I18nextProvider>
  );
}
