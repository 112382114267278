import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';

import '../styles/index.css';

import Navbar from '../components/Navbar.js';
import Footer from "../components/Footer";
import i18n from "../utils/i18n";

export default function NotFound() {

    const { t } = useTranslation();
    useEffect(() => {
        const savedLanguage = localStorage.getItem('selectedLanguage');
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
        }
    }, []);

    return (
        <>
            <Navbar />
            <div className="not-found-content">
              <p><span>{t('not-found.p1.span1')}</span> {t('not-found.p1.span2')}</p>
            </div>
            <Footer />
        </>
    );
}
