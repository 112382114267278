import React, {useEffect} from 'react';
import ReactGA from "react-ga4";
import { useTranslation } from 'react-i18next';

import '../styles/index.css';

import Navbar from '../components/Navbar.js';
import Footer from "../components/Footer";
import Member from "../components/AboutUs/Member";
import i18n from "../utils/i18n";

export default function AboutUs() {

    ReactGA.send({ hitType: "pageview", page: "/about-us", title: "About Us Page" });

    const { t } = useTranslation();
    useEffect(() => {
        const savedLanguage = localStorage.getItem('selectedLanguage');
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
        }
    }, []);

    return (
        <>
            <Navbar />
            <div className="about-us-content">
                <div className="about-us-content-wrapper">
                    <div className="about-us-content-titles">
                        <p>{t('about-us.p1.span1')} <span>{t('about-us.p1.span2')}</span></p>
                        <p>{t('about-us.p2')}</p>
                        <p>{t('about-us.p3')}</p>
                    </div>
                    <div className="about-us-content-team">
                        <Member
                            image={"leonardo"}
                            name={"Leonardo Bressan"}
                            role={t('about-us.p4')}
                            linkedinURL={"https://www.linkedin.com/in/leonardo-bressan-profile"}
                            emailURL={"mailto:leonardo.bressan@givit.app"}
                            overlay={true}
                        />
                        <Member
                            image={"massimiliano"}
                            name={"Massimiliano Giunchi"}
                            role={t('about-us.p5')}
                            linkedinURL={"https://www.linkedin.com/in/massimiliano-giunchi-1a075a234/"}
                            emailURL={"mailto:massimiliano.giunchi@givit.app"}
                            overlay={false}
                        />
                        <Member
                            image={"federico"}
                            name={"Federico Calandra"}
                            role={t('about-us.p6')}
                            linkedinURL={"https://www.linkedin.com/in/federico-calandra/"}
                            emailURL={"mailto:federico.calandra@givit.app"}
                            overlay={false}
                        />
                        <Member
                            image={"lorenzo"}
                            name={"Lorenzo Bevilacqua"}
                            role={t('about-us.p7')}
                            linkedinURL={"https://www.linkedin.com/in/lbevilacqua/"}
                            emailURL={"mailto:lorenzo.bevilacqua@givit.app"}
                            overlay={false}
                        />
                        <Member
                            image={"tommaso"}
                            name={"Tommaso Dell'Angelo"}
                            role={t('about-us.p8')}
                            linkedinURL={"https://www.linkedin.com/in/tommasodellangelo/"}
                            emailURL={"mailto:tommaso.dellangelo@givit.app"}
                            overlay={false}
                        />
                        <Member
                            image={"nicola"}
                            name={"Nicola Galberti"}
                            role={t('about-us.p9')}
                            linkedinURL={"https://www.linkedin.com/in/nicola-galberti/"}
                            emailURL={"mailto:nicola.galberti@givit.app"}
                            overlay={true}
                        />
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}
