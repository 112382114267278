import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { IoClose, IoMenu } from "react-icons/io5";
import Select from "react-select";

import '../styles/index.css';

import logo from '../assets/logo.png';
import italianFlag from '../assets/flags/italy.png';
import englishFlag from '../assets/flags/usa.png';
import spanishFlag from '../assets/flags/spain.png';
import germanFlag from '../assets/flags/germany.png';
import frenchFlag from '../assets/flags/france.png';

export default function Navbar() {

    const [selectedCountry, setSelectedCountry] = useState('it');
    const [showMenu, setShowMenu] = useState(false);
    const { t } = useTranslation();
    const { i18n } = useTranslation();

    useEffect(() => {
        const savedLanguage = localStorage.getItem('selectedLanguage');
        const selectedOption = options.find(option => option.value === savedLanguage);
        if(selectedOption === undefined){
            setSelectedCountry({ value: 'en', label: <img src={englishFlag} width="30px" alt={"Italian flag"}/> },)
        }else{
            if (savedLanguage) {
                i18n.changeLanguage(savedLanguage);
                setSelectedCountry(selectedOption);
            }
        }
    }, [i18n]);

    const customStyles = {
        control: (provided) => ({
            ...provided,
            boxShadow: "none",
            border: 'none',
        }),
    };

    const options = [
        { value: 'it', label: <img src={italianFlag} width="30px" alt={"Italian flag"}/> },
        { value: 'en', label: <img src={englishFlag} width="30px" alt={"Italian flag"}/> },
        { value: 'sp', label: <img src={spanishFlag} width="30px" alt={"Italian flag"}/> },
        { value: 'ge', label: <img src={germanFlag} width="30px" alt={"Italian flag"}/> },
        { value: 'fr', label: <img src={frenchFlag} width="30px" alt={"Italian flag"}/> }
    ];

    const handleCountryChange = (countryCode) => {
        const selectedOption = options.find(option => option.value === selectedCountry);
        setSelectedCountry(selectedOption);
        i18n.changeLanguage(countryCode);
        localStorage.setItem('selectedLanguage', countryCode);
    };

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const closeMenuOnMobile = () => {
        if (window.innerWidth <= 1024) {
            setShowMenu(false);
        }
    };

    return (
      <div className="navigation-bar">
          <div className="navigation-bar-toggle" id="nav-toggle" onClick={toggleMenu}>
              <IoMenu/>
          </div>
          <div className={`navigation-bar-menu${showMenu ? "-show" : ""}`}>
              <div className="navigation-bar-close" id="nav-close" onClick={toggleMenu}>
                  <IoClose/>
              </div>
              <a href="/application" onClick={closeMenuOnMobile}>{t('navbar.p1')}</a>
              <a href="/sustainability" onClick={closeMenuOnMobile}>{t('navbar.p2')}</a>
              {/*<a href="/blog" onClick={closeMenuOnMobile}>Blog</a>}*/}
              <a href="/about-us" onClick={closeMenuOnMobile}>{t('navbar.p3')}</a>
              {/*<a href="/business" onClick={closeMenuOnMobile}>Business</a>*/}
              <a href="/contact-us" onClick={closeMenuOnMobile}>{t('navbar.p4')}</a>
              <Select
                  options={options}
                  onChange={(selectedOption) => handleCountryChange(selectedOption.value)}
                  value={selectedCountry}
                  placeholder={selectedCountry ? selectedCountry.label : "Select..."}
                  styles={customStyles}
              />
          </div>
          <div className="navigation-bar-links-sx">
              <a href="/application">{t('navbar.p1')}</a>
              <a href="/sustainability">{t('navbar.p2')}</a>
          </div>
          <div className="navigation-bar-brand">
              <img src={logo} alt="Givit logo"/>
              <a href="/">Givit</a>
          </div>
          <div className="navigation-bar-links-dx">
              {/*<a href="/blog">Blog</a>}*/}
              <a href="/about-us">{t('navbar.p3')}</a>
              {/*<a href="/business">Business</a>*/}
              <a href="/contact-us">{t('navbar.p4')}</a>
              <Select
                  options={options}
                  onChange={(selectedOption) => handleCountryChange(selectedOption.value)}
                  value={selectedCountry}
                  placeholder={selectedCountry ? selectedCountry.label : "Select..."}
                  styles={customStyles}
              />
          </div>
      </div>
  );

}
