import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import '../../styles/index.css';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import { ToastContainer } from 'react-toastify';
import notify from '../../utils/local_notification';
import i18n from "../../utils/i18n";

const queryParameters = new URLSearchParams(window.location.search);

export default function RecoverPassword() {

    const sendRecoveryRequest = async (setRedirect) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/user/recover-password`, {
                userId: queryParameters.get('userId'),
                newPassword: document.getElementsByName('password-input')[0].value,
            });

            if (response.status === 201) {
                notify('success', t('toasts.recover-password.success'));
                setTimeout(() => {
                    setRedirect(true);
                }, 2000);
            } else {
                notify('error', t('toasts.recover-password.error'));
            }
        } catch (error) {
            notify('error', t('toasts.recover-password.error'));
            console.error(error);
        }
    }

    const [redirect, setRedirect] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
        const savedLanguage = localStorage.getItem('selectedLanguage');
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
        }
    }, []);

    if (!queryParameters.get('userId')) {
        return <Navigate to='/' />;
    }

    if (redirect) {
        return <Navigate to='/' />;
    }

    return (
        <>
            <Navbar />
            <div className='pwd'>
                <div className='pwd-wrapper'>
                    <h1 className='pwd-title'>{t('recover-password.p1')}</h1>
                    <div className='pwd-instructions'>
                        {t('recover-password.p2')}
                    </div>
                    <form className='pwd-form'>
                        <input
                            className='pwd-input'
                            type='password'
                            name='password-input'
                            placeholder={t('recover-password.p3')}
                        />
                        <br />
                        <button className='pwd-button' type='button' onClick={() => sendRecoveryRequest(setRedirect)}>
                            {t('recover-password.p4')}
                        </button>
                    </form>
                    <div className='pwd-note'>
                        <p>{t('recover-password.p5')}</p>
                        <p>{t('recover-password.p6')}</p>
                        <p>{t('recover-password.p7')}<a href={"mailto://contact@givit.app"}> contact@givit.app.</a></p>
                    </div>
                </div>
            </div>
            <ToastContainer />
            <Footer />
        </>
    );
}
