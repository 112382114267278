import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from '../translations/english.json';
import translationFR from '../translations/french.json';
import translationGE from '../translations/german.json';
import translationIT from '../translations/italian.json';
import translationSP from '../translations/spanish.json';

i18next.use(initReactI18next).init({
    resources: {
        en: {
            translation: translationEN,
        },
        fr: {
            translation: translationFR,
        },
        ge: {
            translation: translationGE,
        },
        it: {
            translation: translationIT,
        },
        sp: {
            translation: translationSP,
        }
    },
    fallbackLng: 'it',
    interpolation: {
        escapeValue: false,
    },
});

export default i18next;
