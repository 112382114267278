import React, {useState} from 'react';
import validator from 'validator';
import sendEventAnalytics from '../services/analytics.js';
import {ToastContainer} from "react-toastify";
import notify from "../utils/local_notification";
import axios from "axios";
import { useTranslation } from 'react-i18next';

import '../styles/index.css';

import linkedin from '../assets/linkedin.png';
import instagram from '../assets/instagram.png';
import facebook from '../assets/facebook.png';

export default function Footer() {

    const [requestSent, setRequestSent] = useState(false);
    const { t } = useTranslation();

    const subscribeToNewsletter = async () => {
        setRequestSent(true);
        try {
            if (validator.isEmail(document.getElementById("emailInput").value)) {
                const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/newsletter/subscribe`, {
                    email: document.getElementById("emailInput").value,
                }, {
                    validateStatus: function (status) {
                        return status === 200 || status === 409 || status === 500;
                    },
                });

                if (response.status === 200) {
                    notify("success", t('toasts.footer.success'));
                } else if (response.status === 409) {
                    notify("information", t('toasts.footer.information'));
                } else {
                    notify("error", t('toasts.footer.error'));
                }
                document.getElementById("emailInput").value = "";
                setRequestSent(false);
            } else {
                notify("warning", t('toasts.footer.warning'));
                setRequestSent(false);
            }
        } catch (error) {
            console.error(error);
            notify("error", t('toasts.footer.error'));
            setRequestSent(false);
        }
    };

    return (
        <>
            <ToastContainer />
            <div className="footer">
                <div className="footer-wrapper">
                    <div className="footer-titles">
                        <p>{t('footer.p1')}</p>
                        <p>{t('footer.p2')}</p>
                    </div>
                    <div className="footer-input">
                        <input id={"emailInput"} type="text" name="email" placeholder="Email"/>
                        <div className="footer-input-submit-button" onClick={subscribeToNewsletter}>
                            {requestSent ? <span className="material-symbols-outlined">more_horiz</span> :
                                <span className="material-symbols-outlined">send</span>}
                        </div>
                    </div>
                    <div className="footer-privacy">
                        <p>{t('footer.p3')}</p>
                    </div>
                    <div className="footer-links">
                        <a href="/application">{t('footer.p4')}</a>
                        <a href="/sustainability">{t('footer.p5')}</a>
                        {/*<a href="/blog">Blog</a>*/}
                        <a href="/about-us">{t('footer.p6')}</a>
                        {/*<a href="/business">Business</a>*/}
                        <a href="/contact-us">{t('footer.p7')}</a>
                    </div>
                    <div className="footer-socials">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a target={"_blank"} href="https://www.linkedin.com/company/givitapp/" rel="noreferrer"><img
                            onClick={sendEventAnalytics("Social Network", "Social Network Visit", "Linkedin")}
                            src={linkedin} alt="Linkedin logo"/></a>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a target={"_blank"} href="https://www.instagram.com/givit.app?igsh=OGQ5ZDc2ODk2ZA=="
                           rel="noreferrer"><img
                            onClick={sendEventAnalytics("Social Network", "Social Network Visit", "Instagram")}
                            src={instagram} alt="Instagram logo"/></a>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a target={"_blank"} href="https://www.facebook.com" rel="noreferrer"><img
                            onClick={sendEventAnalytics("Social Network", "Social Network Visit", "Facebook")}
                            src={facebook} alt="Facebook logo"/></a>
                    </div>
                </div>
            </div>
        </>
    );
}