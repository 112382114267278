import React, {useEffect} from 'react';
import {useLocation} from "react-router-dom";
import ReactGA from "react-ga4";
import { useTranslation } from 'react-i18next';

import '../styles/index.css';

import Navbar from '../components/Navbar.js';
import Footer from "../components/Footer";
import SustainabilityWidget from "../components/Sustainability/SustainabilityWidget";
import i18n from "../utils/i18n";

export default function Sustainability() {

    ReactGA.send({hitType: "pageview", page: "/sustainability", title: "Sustainability Page"});

    const { t } = useTranslation();
    useEffect(() => {
        const savedLanguage = localStorage.getItem('selectedLanguage');
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
        }
    }, []);

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <Navbar/>
            <div className="sustainability-content">
                <div className="sustainability-content-wrapper">
                    <div className="sustainability-content-titles">
                        <p>{t('sustainability.p1.span1')} <span>{t('sustainability.p1.span2')}</span></p>
                        <p>{t('sustainability.p2')}</p>
                    </div>
                    <SustainabilityWidget title={t('sustainability.p3')}
                                          content={t('sustainability.p4')}
                                          reverse={false}
                                          alignment={false}
                                          index={1}
                    />
                    <SustainabilityWidget title={t('sustainability.p5')}
                                          content={t('sustainability.p6')}
                                          reverse={false}
                                          alignment={true}
                                          index={2}
                    />
                    <SustainabilityWidget title={t('sustainability.p8')}
                                          content={t('sustainability.p7')}
                                          reverse={true}
                                          alignment={true}
                                          index={3}
                    />
                </div>
            </div>
            <Footer/>
        </>
    );
}
