import React, {useEffect, useState} from 'react';
import { ToastContainer } from 'react-toastify';
import axios from "axios";
import validator from 'validator';
import ReactGA from "react-ga4";
import notify from "../utils/local_notification.js";
import { useTranslation } from 'react-i18next';

import '../styles/index.css';
import 'react-toastify/dist/ReactToastify.css';

import Navbar from '../components/Navbar.js';
import Footer from "../components/Footer.js";
import i18n from "../utils/i18n";

export default function ContactUs() {

    ReactGA.send({ hitType: "pageview", page: "/contact-us", title: "Contact Us Page" });

    const { t } = useTranslation();
    useEffect(() => {
        const savedLanguage = localStorage.getItem('selectedLanguage');
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
        }
    }, []);

    const [requestSent, setRequestSent] = useState(false);

    const checkParametersValidity = async () => {
         return (validator.isEmail(document.getElementsByName("email")[0].value) &&
             document.getElementsByName("name")[0].value !== "" &&
             document.getElementsByName("message")[0].value !== "");
    }

    const sendRequest = async () => {
        if(await checkParametersValidity()){
            if(!requestSent){
                setRequestSent(true);
                await axios.post(`${process.env.REACT_APP_SERVER_URL}/contact/send-request`, {
                    name: document.getElementsByName("name")[0].value,
                    email: document.getElementsByName("email")[0].value,
                    content: document.getElementsByName("message")[0].value,
                })
                .then(function (response) {
                    if(response){
                        notify("success", t('toasts.contact-us.success'));
                        document.getElementsByName("name")[0].value = "";
                        document.getElementsByName("email")[0].value = "";
                        document.getElementsByName("message")[0].value = "";
                        setRequestSent(false);
                    }else{
                        notify("error", t('toasts.contact-us.error'));
                        setRequestSent(false);
                    }
                })
                .catch(function (error) {
                    notify("error", t('toasts.contact-us.error'));
                    setRequestSent(false);
                });
            }else{
                notify("information", t('toasts.contact-us.information'));
                setRequestSent(false);
            }
        }else{
            notify("warning", t('toasts.contact-us.warning'));
        }
    }

    return (
        <>
            <Navbar/>
            <div className="contact-us-content">
                <div className="contact-us-content-wrapper">
                    <div className="contact-us-content-titles">
                        <p>{t('contact-us.p1.span1')} <span>{t('contact-us.p1.span2')}</span></p>
                        <p>{t('contact-us.p2')}</p>
                    </div>
                    <div className="contact-us-content-form">
                        <div className="contact-us-content-form-inputs">
                            <div className="contact-us-inputs-overlay">
                                <input type="text" name={"name"} placeholder={t('contact-us.p3')}/>
                            </div>
                            <div className="contact-us-inputs-overlay">
                                <input type="email" name={"email"} placeholder="Email"/>
                            </div>
                        </div>
                        <div className="contact-us-content-form-textarea">
                            <textarea name="message" rows="10" placeholder={t('contact-us.p4')}/>
                            <div onClick={sendRequest} className="contact-us-content-form-textarea-submit-button">
                                {requestSent ? <span className="material-symbols-outlined">more_horiz</span> : <span className="material-symbols-outlined">send</span>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer/>
            <Footer/>
        </>
    );

}
