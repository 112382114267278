import React, {useEffect} from 'react';
import {useLocation} from "react-router-dom";
import ReactGA from "react-ga4";
import { useTranslation } from 'react-i18next';

import '../styles/index.css';

import Navbar from '../components/Navbar.js';
import Footer from "../components/Footer";
import ApplicationWidget from "../components/Application/ApplicationWidget";
import i18n from "../utils/i18n";

export default function Application() {

    ReactGA.send({ hitType: "pageview", page: "/application", title: "App Page" });

    const { t } = useTranslation();

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
        const savedLanguage = localStorage.getItem('selectedLanguage');
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
        }
    }, [pathname]);

    return (
        <>
            <Navbar/>
            <div className="application-content">
                <div className="application-content-titles">
                    <p>{t('app.p1.span1')} <span>{t('app.p1.span2')}</span></p>
                    <p>{t('app.p2')}</p>
                </div>
                <div className="application-content-upcoming">
                    <p>{t('app.p3')}</p>
                </div>
                <div className="application-content-steps">
                    <ApplicationWidget
                        title={t('app.p4')}
                        content={t('app.p5')}
                        index={1}
                    />
                    <ApplicationWidget
                        title={t('app.p6')}
                        content={t('app.p7')}
                        index={2}
                    />
                    <ApplicationWidget
                        title={t('app.p8')}
                        content={t('app.p9')}
                        index={3}
                    />
                    <ApplicationWidget
                        title={t('app.p10')}
                        content={t('app.p11')}
                        index={4}
                    />
                </div>
            </div>
            <Footer/>
        </>
    );
}