import React, {useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import { useTranslation } from 'react-i18next';

import '../styles/index.css';

import Navbar from '../components/Navbar.js';
import Footer from "../components/Footer";
import LogoBottom from "../components/Home/LogoBottom";
import PurposeElement from "../components/Home/PurposeElement";

import homepageImage from '../assets/homepage_image.png';
import homepageExplanationImage1 from '../assets/homepage_explanation_images/homepage_exp_image1.png'
import homepageExplanationImage2 from '../assets/homepage_explanation_images/homepage_exp_image2.png'
import homepageExplanationImage3 from '../assets/homepage_explanation_images/homepage_exp_image3.png'
import homepageExplanationImage4 from '../assets/homepage_explanation_images/homepage_exp_image4.png'
import homepageExplanationImage5 from '../assets/homepage_explanation_images/homepage_exp_image5.png'
import homepageExplanationImage6 from '../assets/homepage_explanation_images/homepage_exp_image6.png'
import purposeImage1 from '../assets/homepage_purpose_images/purpose-image-1.jfif'
import purposeImage2 from '../assets/homepage_purpose_images/purpose-image-2.jfif'
import purposeImage3 from '../assets/homepage_purpose_images/purpose-image-3.jfif'
import purposeImage4 from '../assets/homepage_purpose_images/purpose-image-4.jfif'
import i18n from "../utils/i18n";

export default function Homepage() {
    ReactGA.send({ hitType: "pageview", page: "/", title: "Home Page" });

    const { t } = useTranslation();
    useEffect(() => {
        const savedLanguage = localStorage.getItem('selectedLanguage');
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
        }
    }, []);

    const navigate = useNavigate();

    const navigateToApplicationPage = () => {
        navigate("/application");
    }

    return (
        <>
        <Navbar />
            <div className="homepage-content">
                <div className="homepage-content-stack">
                    {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                    <p><span>Give it</span> a longer life!</p>
                    <p id="main_logo">Givit</p>
                    {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                    <img src={homepageImage} alt="Homepage image" />
                </div>
                <div className="homepage-content-explanation">
                    <p>{t('homepage.p1.span1')} <span>Givit</span></p>
                    <p>{t('homepage.p2')}</p>
                    <div onClick={navigateToApplicationPage} className="homepage-content-explanation-button">{t('homepage.p3')}</div>
                </div>
                <div className="homepage-content-bottom-images">
                    <img src={homepageExplanationImage1} alt=""></img>
                    <img src={homepageExplanationImage2} alt=""></img>
                    <img src={homepageExplanationImage3} alt=""></img>
                    <img src={homepageExplanationImage4} alt=""></img>
                    <img src={homepageExplanationImage5} alt=""></img>
                    <img src={homepageExplanationImage6} alt=""></img>
                </div>
                <div className="homepage-content-sustainability">
                    <p>{t('homepage.p4.span1')}<span> {t('homepage.p4.span2')}</span></p>
                    <p>{t('homepage.p5')}</p>
                </div>
                <div className="homepage-content-purpose">
                    <div className="homepage-content-purpose-title">
                        <p><span>{t('homepage.p6.span1')}</span> {t('homepage.p6.span2')} <span>{t('homepage.p6.span3')}</span></p>
                    </div>
                    <div className="homepage-content-purpose-gallery">
                        <div className="homepage-content-purpose-gallery-column">
                            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                            <PurposeElement imageUrl={purposeImage1} text={t('homepage.p7')}/>
                            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                            <PurposeElement imageUrl={purposeImage3} text={t('homepage.p8')}/>
                        </div>
                        <div className="homepage-content-purpose-gallery-column">
                            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                            <PurposeElement imageUrl={purposeImage2} text={t('homepage.p9')} link={'/sustainability'}/>
                            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                            <PurposeElement imageUrl={purposeImage4} text={t('homepage.p10')}/>
                        </div>
                    </div>
                </div>
            </div>
            <LogoBottom/>
            <Footer/>
        </>
    );
}
